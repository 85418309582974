// mask.js
document.addEventListener('DOMContentLoaded', function() {
    const phoneInputs = document.querySelectorAll('input[name="tel"]');

    if (phoneInputs.length === 0) {
        return; 
    }

    phoneInputs.forEach(phoneInput => {
        phoneInput.addEventListener('input', function(e) {
            let value = e.target.value;

            value = value.replace(/\D/g, ''); 

            if (value.startsWith('7')) {
                value = value.slice(1); 
            }
            let formattedValue = '+7 ';
            if (value.length > 0) {
                formattedValue += value.slice(0, 3);
            }
            if (value.length > 3) {
                formattedValue += ' ' + value.slice(3, 6);
            }
            if (value.length > 6) {
                formattedValue += ' ' + value.slice(6, 8);
            }
            if (value.length > 8) {
                formattedValue += ' ' + value.slice(8, 10);
            }
            e.target.value = formattedValue;

        });
    });
});



