window.addEventListener('scroll', function() {
    const header = document.querySelector('.header');
    
    if (window.scrollY > 0) {
        header.classList.add('shadow');
    } else {
        header.classList.remove('shadow');
    }
});


document.querySelector('.btn-loop').addEventListener('click', function() {
    document.querySelector('.search-form').classList.toggle('open');
});

