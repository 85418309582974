/*
 * Фокус на input
 */
document.addEventListener('click', function (event) {
    const form = document.querySelector('.form-card');
    
    if (event.target.closest('.field')) {
        let field = event.target.closest('.field');
        let input = field.querySelector('.field__input');
        
        if (input) {
            input.focus();
        }
    } 
    else if (form && !form.contains(event.target)) {
        let isEmpty = Array.from(form.querySelectorAll('.field__input')).every(input => input.value === '' || input.value === '+7 (___) ___-__-__');
        
        if (isEmpty) {
            form.classList.remove('open');
        }
    }
});

document.addEventListener('focusin', function (event) {
    if (event.target.classList.contains('field__input')) {
        event.target.parentNode.classList.add('active');
    }
});

document.addEventListener('focusout', function (event) {
    if (event.target.classList.contains('field__input')) {
        checkField(event.target);
    }
});

function checkField(element) {
    if (element.value === '' || element.value === '+7 (___) ___-__-__') {
        element.parentNode.classList.remove('active');
    } else {
        element.parentNode.classList.add('active');
    }
}











