class Pagination {
	constructor(params) {
		this.params = {
			container		: document.querySelector('.ajax-container'),
			prev			: document.querySelector('.pagination-ajax .pagination-item__prev'),
			next			: document.querySelector('.pagination-ajax .pagination-item__next'),
			links			: document.querySelectorAll('.pagination-ajax .pagination-item__link'),
			ajaxurl 		: window.ajaxurl,
			max_num_pages 	: window.max_num_pages,
			post_per_page 	: window.post_per_page,
			theID			: window.theID,
			current_page	: window.current_page,
			template		: window.template,
			s				: window.s,
		};
		this.initEvents();
	}

	initEvents() {
		$(document).on('click', '.pagination-ajax .pagination-item__prev', (event) => {
			event.preventDefault();
			if( this.params.current_page <= 1 ) return
			this.params.current_page--;
			this.query();
		});
		$(document).on('click', '.pagination-ajax .pagination-item__next', (event) => {
			event.preventDefault();
			if( this.params.current_page >= this.params.max_num_pages ) return
			this.params.current_page++;
			this.query();
		});
		$(document).on('click', '.pagination-ajax .pagination-item__link', (event) => {
			event.preventDefault();
			let target = event.target || event.currentTarget
			this.params.current_page = target.dataset.page;
			this.query();
		});
	}

	query() {
		if( this.findGetParam('page') ) {
			this.replaceGetParam('page', this.params.current_page)
		}
		else {
			this.addSearchParams({
				'page'	: this.params.current_page
			})
		}

		let data = {
			'action'		: 'custom_pagination',
			'max_num_pages'	: this.params.max_num_pages,
			'current_page'	: this.params.current_page,
			'post_per_page'	: this.params.post_per_page,
			'theID'			: this.params.theID,
			'template'		: this.params.template,
			's'				: this.params.s
		};

		this.params.container.classList.add('loading');
		$.ajax({
			url		: this.params.ajaxurl,
			data	: data,
			type	: 'POST',
			dataType: 'json',
			success: (data) => {
				this.params.container.innerHTML = data['content']
				document.querySelector('.pagination-ajax').innerHTML = data['pagination']
				$('body,html').stop().animate({
					scrollTop: 0
				}, 400);
				this.params.container.classList.remove('loading');
			}
		});
	}

	addSearchParams(params) {
		let search = location.search == '' ? '?' : location.search

		let i = 0;
		for (let [key, value] of Object.entries(params)) {
			i++;
			if( location.search == '' ) {
				if( i == 1 ) search += key+'='+value
				else search += '&'+key+'='+value
			} else {
				search += '&'+key+'='+value
			}
		}

		history.pushState({}, '', location.origin + location.pathname.slice(0, -1) + search)
	}

	replaceGetParam(prmName,val) {
		var res = '';
		var d = location.href.split("#")[0].split("?");
		var base = d[0];
		var query = d[1];
		if(query) {
			var params = query.split("&");
			for(var i = 0; i < params.length; i++) {
				var keyval = params[i].split("=");
				if(keyval[0] != prmName) {
					res += params[i] + '&';
				}
			}
		}
		res += prmName + '=' + val;
		history.pushState({}, '', base + '?' + res)
		return false;
	}

	findGetParam(param) {
		let getparams = decodeURI(location.search).replace('?', '').split('&');
		let value = false;

		getparams.forEach((el, index) => {
			let arr = el.split('=');
			let key = arr[0];
			let val = arr[1];

			if( param == key ) value = val;
		})

		return value;
	}
}

if(document.querySelector('.pagination-ajax')) {window.pagination = new Pagination()}