import "./main.scss";
import "swiper/css"
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/controller';
import "htmx.org"

import "./js/alpine";
import "./js/modal";

import "./js/index/index";
import "./js/index/mask";
import "./js/index/input";
import "./js/index/feedback";

import "./js/parts/mobile";
import "./js/parts/swiper";
import "./js/parts/question";
import "./js/parts/btn";
import "./js/parts/pagination";