const buttons = document.querySelectorAll('.btn-none-text');
const cards = document.querySelectorAll('.btn-none-card');

buttons.forEach(button => {
    button.addEventListener('click', () => {
        buttons.forEach(btn => btn.classList.remove('active'));
        
        button.classList.add('active');

        const selectedValue = button.getAttribute('data-atr');

        cards.forEach(card => {
            card.style.display = 'none';
        });

        const matchingCard = [...cards].find(card => card.getAttribute('data-atr') === selectedValue);
        if (matchingCard) {
            matchingCard.style.display = 'block';
        }
    });
});
