const cards = document.querySelectorAll('.cc');

cards.forEach(card => {
    card.addEventListener('click', function() {
        this.classList.toggle('active');

        const btn = this.querySelector('.question__btn');
        if (btn) {
            btn.classList.toggle('active');
        }

/*         const answer = this.querySelector('.question__answer');
        if (answer) {
            if (answer.style.display === 'block') {
                answer.style.display = 'none';
            } else {
                answer.style.display = 'block';
            }
        } */
    });
});

