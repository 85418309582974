import Swiper from 'swiper';
import { Navigation, Scrollbar, Thumbs, Pagination } from 'swiper/modules';

var swiper = new Swiper(".swiper-container", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    grabCursor: true,
    freeMode: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        480: {
            slidesPerView: 'auto',
            freeMode: true,
        }
    }
  });

  var swiper = new Swiper(".swiper-file", {
    modules: [Scrollbar], 
    slidesPerView: 1,
    spaceBetween: 30,
    grabCursor: true,
    freeMode: false,
    scrollbar: { 
        el: '.swiper-scrollbar',
        draggable: true, 
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        freeMode: true,
    },
      1000: {
          slidesPerView: 3,
          freeMode: true,
      }
  }
});


var swiperTmb = new Swiper(".swiper-tmb", {
  modules: [Navigation, Thumbs, Pagination],
  loop: false, 
  spaceBetween: 10,
  slidesPerView: 1,
  freeMode: true,
  watchSlidesProgress: true,
  breakpoints: {
    480: {
      slidesPerView: 2,
      freeMode: true,
  },
    600: {
      slidesPerView: 3,
      freeMode: true,
  },
    950: {
        slidesPerView: 4,
        freeMode: true,
    }
}
});

var swiperMain = new Swiper(".swiper-tmb-2", {
  modules: [Navigation, Thumbs, Pagination],
  loop: false, 
  spaceBetween: 10, 
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
  thumbs: {
    swiper: swiperTmb, 
  },
});


var swiperFirst = new Swiper(".swiper-product", {
  modules: [Scrollbar, Thumbs],
  loop: false, 
  spaceBetween: 10,
  slidesPerView: 3,
  direction: "vertical",
  watchSlidesProgress: true,
});

var swiperMain = new Swiper(".swiper-product-2", {
  modules: [Scrollbar, Thumbs],
  loop: false,
  direction: "vertical", 
  spaceBetween: 10,
  thumbs: {
    swiper: swiperFirst, 
  },
  scrollbar: { 
    el: '.swiper-scrollbar',
    clickable: true, 
  },
});


