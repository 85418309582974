document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.getElementById('menu-toggle');
    if (menuToggle) {
        menuToggle.addEventListener('click', function() {
            this.classList.toggle('active');
    
            const mobileMenu = document.getElementById('mobile-menu');
            const header = document.querySelector('.header');
    
            if (mobileMenu) {
                mobileMenu.classList.toggle('active');
            }
    
            if (header) {
                header.classList.toggle('active'); 
            }
        });
    }
});

